exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dream-plus-tsx": () => import("./../../../src/pages/dream-plus.tsx" /* webpackChunkName: "component---src-pages-dream-plus-tsx" */),
  "component---src-pages-humake-fitness-tsx": () => import("./../../../src/pages/humake-fitness.tsx" /* webpackChunkName: "component---src-pages-humake-fitness-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-slab-tsx": () => import("./../../../src/pages/slab.tsx" /* webpackChunkName: "component---src-pages-slab-tsx" */),
  "component---src-pages-y-gym-tsx": () => import("./../../../src/pages/y-gym.tsx" /* webpackChunkName: "component---src-pages-y-gym-tsx" */),
  "component---src-pages-zm-fpbm-rlcn-nha-x-fy-tsx": () => import("./../../../src/pages/ZmFpbmRlcnNhaXFy.tsx" /* webpackChunkName: "component---src-pages-zm-fpbm-rlcn-nha-x-fy-tsx" */)
}

